import apiFunc from '@/api/index'
import { ref, computed, onMounted } from 'vue'

const api = apiFunc()

export default function useATC () {
  /* DATA */
  const config = ref([])

  /* COMPUTED */
  const total = computed(() => {
    return config.value.reduce((acc, item) => {
      if (item.model !== null) {
        const curOption = item.options.find(i => i.value === item.model)

        return acc + curOption.value * item.price
      }

      return acc
    }, 0)
  })

  const receipt = computed(() => {
    const options = []

    config.value.forEach(item => {
      if (item.model !== null) {
        options.push({
          key: item.label,
          val: item.options.find(i => i.value === item.model).text
        })
      }
    })

    return [
      {
        title: 'Конфигурация',
        options
      }
    ]
  })

  const requestBody = computed(() => {
    const result = {}

    config.value.forEach(i => {
      result[i.id] = i.model
    })

    return result
  })

  /* METHODS */

  const fetchATC = async () => {
    const res = await api.services.fetchATC()
    const data = res.data ?? {}

    const internalCalls = data?.internalCalls ?? {}
    const simultaneousCalls = data?.simultaneousCalls ?? {}
    const archiveRecordingDay = data?.archiveRecordingDay ?? {}

    const generateOptions = ({ min, max, step = 1 }) => {
      const options = []

      for (let i = min; i <= max; i += step) {
        options.push({
          value: i,
          text: `${i} шт`
        })
      }

      return options
    }

    config.value = [
      {
        id: 'internalCalls',
        icon: '/images/configicon1.svg',
        label: 'Внутренние номера',
        price: internalCalls.price,
        options: generateOptions(internalCalls),
        model: internalCalls.min
      },
      {
        id: 'simultaneousCalls',
        icon: '/images/configicon2.svg',
        label: 'Одновременных звонков',
        price: simultaneousCalls.price,
        options: generateOptions(simultaneousCalls),
        model: simultaneousCalls.min
      },
      {
        id: 'archiveRecordingDay',
        icon: '/images/configicon3.svg',
        label: 'Архив записей разговоров',
        price: archiveRecordingDay.price,
        options: generateOptions(archiveRecordingDay),
        model: archiveRecordingDay.min
      }
    ]
  }

  onMounted(fetchATC)

  return {
    config,
    total,
    requestBody,
    receipt
  }
}
