<template>
  <main class="virtual-atc">
    <AppPreview
      title="Виртуальный АТС"
      text="Организуйте умную телефонию в своей компании. Настройте прием звонков на единый многоканальный номер."
    >
      <img src="/images/preview122.png" alt="preview">
    </AppPreview>

    <section class="guide">
      <div class="container">
        <h2 class="guide__title">Подключить Виртуальную АТС легко</h2>
        <p class="guide__text">
          Принимайте звонки на SIP-телефон, мобильный или компьютер. Настройте голосовое приветствие и распределение звонков на любые мобильные и стационарные телефоны. Собирайте аналитику по работе с клиентами, используя статистику вызовов и возможность записи разговоров.
        </p>
      </div>
    </section>

    <section class="advantages">
      <div class="container">
        <h2>Преимущества выбора Cloud bit</h2>

        <div class="advantages__list">
          <AppAdvantage
            v-for="(advantage, index) of advantages"
            :key="index"
            :image="advantage.image"
            :title="advantage.title"
            :text="advantage.text"
          />
        </div>
      </div>
    </section>

    <section class="config">
      <div class="container">
        <h2>Конфигурация</h2>

        <div class="config-items">
          <div
            v-for="(item, index) of atcConfig"
            :key="index"
            class="config-item"
          >
            <div class="config-item__icon-wrap">
              <img :src="item.icon">
            </div>

            <p class="config-item__label">
              {{ item.label }}
            </p>

            <BaseSelect
              v-model="item.model"
              :options="item.options"
              class="config-item__select"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="requirements">
      <div class="container">
        <h2 class="requirements__title">Что потребуется для подключения виртуальной АТС?</h2>
        <p class="requirements__text">Только доступ в Интернет и одно или несколько устройств для приёма звонков (IP-телефоны, компьютеры или смартфоны). Если сотрудники не имеют постоянного доступа в Интернет — вы сможете настроить переадресацию звонков на их мобильные номера.</p>
      </div>
    </section>

    <section class="opportunities">
      <div class="container">
        <h2>Возможности Виртуальной АТС</h2>
        <ul class="opportunities__list">
          <li
            v-for="(item, index) of opportunities"
            :key="index"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </section>

    <section class="additional">
      <div class="container">
        <h2>Дополнительные услуги</h2>
        <div class="additional__checkboxes">
          <div
            v-for="(item, index) of additionalItems"
            :key="index"
          >
            <BaseCheckbox
              v-model="additionalSelected"
              :label="item.label"
              :value="item.value"
              class="additional__checkbox"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="backup-section">
      <div class="container">
        <BaseSwitches v-model="backupEnabled">
          <template #label>
            <h2>{{ backupExtraData.title }}</h2>
          </template>
        </BaseSwitches>

        <div class="desc">
          {{ backupExtraData.desc }}
        </div>

        <div class="calc">
          <div
            v-for="(item, index) of backupItems"
            :key="index"
            class="calc-row"
          >
            <p class="calc-row__title">{{ item.title }}</p>
            <BaseInputRange
              v-model="item.model"
              :min="item.min"
              :max="item.max"
              :step="item.step"
              :disabled="!backupEnabled"
              class="calc-row__slider"
            />
            <p class="calc-row__val">{{ item.model }} {{ item.unit }}</p>
          </div>
        </div>
      </div>
    </section>

    <section
      class="container"
      style="margin-top: 60px;"
    >
      <PanelTotal
        :price="globalTotal"
        @open="$refs.requestModal.open()"
      />
    </section>

    <ModalRequest
      ref="requestModal"
      :total="globalTotal"
      @submit="onSubmit"
    >
      <AppReceipt :receipt="globalReceipt" />
    </ModalRequest>

    <ModalThank ref="modalThank" />
  </main>
</template>

<script>
import useAdditional from '@/composables/useAdditional'
import useBackup from '@/composables/useBackup'
import useATC from '@/composables/useATC'

import PanelTotal from '@/components/PanelTotal'
import AppReceipt from '@/components/AppReceipt'
import ModalRequest from '@/components/ModalTemplates/ModalRequest'
import ModalThank from '@/components/ModalTemplates/ModalThank'
import AppPreview from '@/components/AppPreview'
import AppAdvantage from '@/components/AppAdvantage'
import BaseSelect from '@/components/Base/BaseSelect'
import BaseSwitches from '@/components/Base/BaseSwitches'
import BaseInputRange from '@/components/Base/BaseInputRange'
import BaseCheckbox from '@/components/Base/BaseCheckbox'

export default {
  name: 'VirtualATCPage',
  components: {
    PanelTotal,
    AppReceipt,
    ModalRequest,
    ModalThank,
    AppPreview,
    AppAdvantage,
    BaseSelect,
    BaseSwitches,
    BaseInputRange,
    BaseCheckbox
  },
  setup () {
    const {
      config: atcConfig,
      total: atcTotal,
      receipt: atcReceipt,
      requestBody: atcRequestBody
    } = useATC()

    const {
      receipt: additionalReceipt,
      total: additionalTotal,
      selected: additionalSelected,
      items: additionalItems
    } = useAdditional('/service/atc/additionally')

    const {
      enabled: backupEnabled,
      receipt: backupReceipt,
      total: backupTotal,
      requestBody: backupRequestBody,
      extraData: backupExtraData,
      items: backupItems
    } = useBackup()

    return {
      atcConfig,
      atcTotal,
      atcReceipt,
      atcRequestBody,

      additionalReceipt,
      additionalTotal,
      additionalSelected,
      additionalItems,

      backupEnabled,
      backupExtraData,
      backupTotal,
      backupRequestBody,
      backupReceipt,
      backupItems
    }
  },
  data () {
    return {
      advantages: [
        {
          image: '/images/adv81.svg',
          title: 'Номер останется за вами',
          text: 'Номер телефона не привязан к адресу подключения и даже при смене офиса останется за вами.'
        },
        {
          image: '/images/adv9.svg',
          title: 'Номер никогда не будет занят',
          text: 'Всегда ответит нужный сотрудник и звонки будут распределены равномерно.'
        },
        {
          image: '/images/adv10.svg',
          title: 'Простое и удобное управление',
          text: 'Поменять все настройки можно в личном кабинете в режиме реального времени.'
        }
      ],
      opportunities: [
        'Мобильные рабочие места',
        'Запись разговоров',
        'Интеграция с CRM',
        'Виртуальный контактный центр',
        'Автоинформатор'
      ]
    }
  },
  computed: {
    globalTotal () {
      return this.atcTotal + this.additionalTotal + this.backupTotal
    },
    globalReceipt () {
      return [
        ...this.atcReceipt,
        ...this.additionalReceipt,
        ...this.backupReceipt
      ]
    }
  },
  methods: {
    async onSubmit (e) {
      const { surname, name, patron, companyName, email, phone } = e

      try {
        const res = await this.$api.common.sendClientData({
          surname, name, patronymic: patron, companyName, email, phone
        })
        const applicationId = res.data?.applicationId

        await this.$api.services.sendATC({
          applicationId,
          service: this.atcRequestBody,
          additionally: this.additionalSelected,
          backup: this.backupRequestBody,
          price: this.globalTotal
        })

        this.$refs.requestModal.close()
        this.$refs.requestModal.clear()
        this.$refs.modalThank.open()
      } catch (e) {
        console.log(e)
        alert('Произошла ошибка!')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.virtual-atc {
  .guide {
    padding: 40px 0;
    &__title {}
    &__text {
      font-size: 18px;
      margin-top: 40px;
      width: 81%;
    }
  }
  .advantages {
    padding: 40px 0;
    &__list {
      margin-top: 50px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 30px;
      grid-auto-rows: 350px;

      @media screen and (max-width: $media-lg) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (max-width: $media-sm) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: $media-esm) {
        grid-template-columns: 1fr;
      }
    }
  }
  .config {
    padding: 40px 0;
    &-items {
      margin-top: 42px;
      display: grid;
      grid-gap: 20px 32px;
      grid-template-columns: 1fr 1fr;

      @media screen and (max-width: $media-md) {
        grid-template-columns: 1fr;
      }
    }
    &-item {
      display: flex;
      align-items: center;

      &__icon-wrap {
        width: 24px;
        display: flex;
        align-items: center;
      }
      &__icon {
        display: block;
        max-width: 100%;
      }
      &__label {
        margin-left: 16px;
      }
      &__select {
        margin-left: auto;
        width: 120px;
      }
    }
  }
  .requirements {
    padding: 40px 0;
    &__text {
      font-size: 18px;
      margin-top: 40px;
      width: 81%;
    }
  }
  .opportunities {
    padding: 40px 0;

    &__list {
      margin-top: 40px;

      li {
        font-size: 20px;
        margin: 10px 0;
      }
    }
  }

  .additional {
    padding: 40px 0;

    &__checkboxes {
      margin-top: 20px;
    }

    &__checkbox {
      margin-top: 14px;
    }
  }

  .backup {
    padding: 40px 0 100px;
  }
}
</style>
